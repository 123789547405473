.react-slideshow-container {
  display: flex;
  align-items: center;
}

.react-slideshow-container .nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-slideshow-container .nav span {
  display: block;
  width: 10px;
  height: 10px;
  border-color: #000;
  border-style: solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.react-slideshow-container .nav.disabled span {
  border-color: #666;
}

.react-slideshow-container .nav:hover {
  background: white;
  color: #666;
}

.react-slideshow-container .nav.disabled:hover {
  cursor: not-allowed;
}

.react-slideshow-container .nav:first-of-type {
  margin-right: -30px;
  border-right: none;
  border-top: none;
}

.react-slideshow-container .nav:first-of-type span {
  margin-left: 5px;
  border-right: none;
  border-top: none;
}

.react-slideshow-container .nav:last-of-type {
  margin-left: -30px;
}

.react-slideshow-container .nav:last-of-type span {
  margin-right: 5px;
  border-left: none;
  border-bottom: none;
}

.react-slideshow-container + div.indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-slideshow-container + div.indicators > div {
  width: 7px;
  height: 7px;
  margin-right: 10px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div:hover {
  background: #666;
}

.react-slideshow-container + div.indicators > div.active {
  background: #000;
}

.react-slideshow-container + div.indicators > div:last-of-type {
  margin-right: 0;
}

.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-wrapper .images-wrap {
  display: flex;
  flex-wrap: wrap;
}

.react-slideshow-fade-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
  display: flex;
  flex-wrap: wrap;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
  position: relative;
  opacity: 0;
}

.react-slideshow-zoom-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-zoom-wrapper .zoom-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.react-slideshow-zoom-wrapper .zoom-wrapper > div {
  position: relative;
  display: flex;
}

@-webkit-keyframes rai-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes rai-spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
.rai-spinner {
  width: 1.5em;
  height: 1.5em;
  position: relative;
  margin: 0; }
  .rai-spinner-inner, .rai-spinner-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    margin-left: -0.0625em;
    margin-top: -0.0625em; }
  .rai-spinner-outer {
    border: 0.125em solid #727981;
    opacity: 0.2; }
  .rai-spinner-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-top: 0.125em solid #727981;
    border-right: 0.125em solid transparent !important;
    border-bottom: 0.125em solid transparent !important;
    border-left: 0.125em solid transparent !important;
    -webkit-animation-name: rai-spinner;
            animation-name: rai-spinner;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: inherit;
            animation-duration: inherit; }

